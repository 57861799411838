// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9Fu563_LRsKske9kP_7dH7xUZwW6YEzM",
  authDomain: "himalayan-fitness.firebaseapp.com",
  projectId: "himalayan-fitness",
  storageBucket: "himalayan-fitness.appspot.com",
  messagingSenderId: "430423471005",
  appId: "1:430423471005:web:2981923abd659316687574"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
