import React, { useRef, useState } from "react";
import "../css/popupform.css"; // Make sure to create this CSS file
import giveawayImage from "../images/giveaway.jpeg";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config/firestore";
// Then in your component:

const PopupForm = ({ onClose }) => {
  const formRef = useRef(null);
  const [message, setMessage] = useState({ text: "", type: "" });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current) return;
    const formData = new FormData(e.target);

    const dataToSubmit = {
      firstName: formData.get("firstName").trim(),
      lastName: formData.get("lastName").trim(),
      email: formData.get("email").trim(),
      findUs: formData.get("findUs").trim(),
    };

    try {
      const docRef = await addDoc(
        collection(db, "giveAwayEntries"),
        dataToSubmit
      );
      console.log("Document has been added", docRef.id);
      // alert("You details have been submitted, you will hear from us soon")
      setMessage({
        text: "Your entry has been successfully submitted! Good luck!",
        type: "success",
      });
      formRef.current.reset();
      // Close the popup after a delay
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("There was an error submitting your entry. Please try again.");
      setMessage({
        text:
          "There was an error submitting your entry. Please try again " + error,
        type: "error",
      });
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <div className="popup-image">
          <img src={giveawayImage} alt="Descriptive alt text" />
        </div>
        <div className="popup-form">
          <h2>Chill Out & Win Big!</h2>
          <p>
            ​Enter now for a chance to win one of five $100 Amazon gift cards.
            It's our way of saying thanks for being cool!
          </p>
          {message.text && (
            <div className={`message ${message.type}`}>{message.text}</div>
          )}
          <form ref={formRef} onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">Enter your first name *</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                required
                placeholder="First name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Enter your last name *</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                required
                placeholder="Last name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Enter your email *</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="findUs">How did you find us? (optional)</label>
              <div className="select-wrapper">
                <select name="findUs" id="findUs" className="enhanced-select">
                  <option value="">Select an option</option>
                  <option value="instagram">Instagram</option>
                  <option value="facebook">Facebook</option>
                  <option value="google">Google</option>
                  <option value="youtube">Youtube</option>
                  <option value="others">Others</option>
                </select>
              </div>
              {/* <textarea
                id="findUs"
                name="findUs"
                rows="3"
                placeholder="Eg: Social media (instagram, facebook)"
              ></textarea> */}
            </div>
            <button type="submit" className="submit-button">
              Submit
            </button>
            <p></p>
            <button
              type="button"
              className="close-button-bottom"
              onClick={onClose}
            >
              Close
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const addData = () => {};

export default PopupForm;
